import initialSellState from './objects/sell-state';
import initialSwapState from './objects/swap-state';
import { enableMapSet, produce } from 'immer';
import { ProductCategoryType } from 'resources/helpers/enums';
import { isSteamInventoryItem } from '../types/sell-types';
import { ProductHelpers } from '../../extensions/product-helpers';

/**
 * @type {AppState}
 */
const initialState = {
    sellState: initialSellState,
    swapState: initialSwapState,
    selectedGames: new Map(),
    viewLocation: 'Sell',
    steamInventory: null
};

/**
 * @param {AppState} state
 * @param {string} category
 * @param {GameForNav | null} game
 */
export function setSelectedGame(state, category, game) {
    return produce(state, draftState => {
        if (game) {
            draftState.selectedGames.set(category, game);
        } else {
            draftState.selectedGames.delete(category);
            draftState.selectedGames = structuredClone(draftState.selectedGames);
        }
    });
}

/**
 * @param {AppState} state
 * @param {'Swap' | 'Sell'} viewLocation
 */
export function setViewLocation(state, viewLocation) {
    return produce(state, draftState => {
        draftState.viewLocation = viewLocation;
    });
}

/**
 * @param {AppState} state
 * @param {SteamInventory | null} steamInventory
 *
 * @returns {AppState}
 */
export function setSteamInventory(state, steamInventory) {
    return produce(state, draftState => {
        draftState.steamInventory = steamInventory;
    });
}

/**
 * @param {SteamInventoryItem | Product} item
 * @param {number} oldFee
 * @param {number} newFee
 */
function recalculateItemPrice(item, oldFee, newFee) {
    if (item.assetId) {
        const basePrice = item.price / (1 - oldFee);
        item.price = basePrice - (basePrice * newFee);
        return;
    }

    const basePrice = item.price / (1 + oldFee);
    item.price = basePrice + (basePrice * newFee);
}

/**
 * @param {AppState} state
 * @param {number} fee
 *
 * @returns {AppState}
 */
export function setSteamInventoryFee(state, fee) {
    return produce(state, draftState => {
        if (draftState.steamInventory) {
            draftState.steamInventory.items?.forEach(x => recalculateItemPrice(x, draftState.steamInventory.fee, fee));
            draftState.sellState.selectedProducts.forEach(x => recalculateItemPrice(x, draftState.steamInventory.fee, fee));
            draftState.steamInventory.fee = fee;
        }
    });
}

/**
 * @param {AppState} state
 * @param {number} fee
 * @param {number} oldFee
 *
 * @returns {AppState}
 */
export function setSelectedProductsFee(state, fee, oldFee) {
    return produce(state, draftState => {
        draftState.swapState.selectedProducts.sellProducts
            .filter(x => isSteamInventoryItem(x))
            .forEach(x => ProductHelpers.recalculateItemPrice(x, this.steamInventory.fee, fee));
        draftState.swapState.selectedProducts.purchaseProducts
            .filter(x => x.productCategory?.name === ProductCategoryType.Skins)
            .forEach(x => ProductHelpers.recalculateItemPrice(x, oldFee, fee));
    });
}

export default initialState;

enableMapSet();
