/**
 * @typedef {Object} SteamInventoryItem
 * @property {string} productId
 * @property {string} name
 * @property {string} type - The type of item, for CSGO this would be the type of weapon this skin belongs to
 * @property {string} category - Like the type property, this would be the weapon category
 * @property {string} collection
 * @property {number | null | undefined} paintSeed
 * @property {number | null} float
 * @property {string} instanceId
 * @property {string} assetId
 * @property {string} rarity
 * @property {string} quality
 * @property {string} iconUrl
 * @property {string} largeIconUrl
 * @property {string} nameColor
 * @property {string | null} exterior
 * @property {string} inspectUrl
 * @property {Sticker[]} stickers
 * @property {string} backgroundColor
 * @property {number} price
 * @property {string} description
 * @property {SteamGame} appId
 *
 * @property {string} localizedValueLabel - Helper value defined during UI runtime to display localized price values (doesn't come form the API)
 * @property {number} localizedValue - Helper value defined during UI runtime to display localized price values (doesn't come form the API)
 */

/**
 * @typedef {Object} Sticker
 * @property {string} name
 * @property {string} url
 */

/**
 * @typedef {Object} SteamInventory
 * @property {SteamInventoryItem[]} items
 * @property {number} fee
 * @property {string | null} updated
 */

/**
 * @param {Product | SteamInventoryItem} item
 * @returns {item is SteamInventoryItem}
 */
export function isSteamInventoryItem(item) {
    return 'assetId' in item;
}

/**
 * @param {Product | SteamInventoryItem} item
 * @returns {item is Product}
 */
export function isCgProduct(item) {
    return !isSteamInventoryItem(item);
}

export {};
