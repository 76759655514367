import { isSteamInventoryItem } from 'resources/helpers/types/sell-types';

/**
 * A collection of helper functions related specifically to products
 */
export class ProductHelpers {
    /**
     * Used on products of the `Skins` category, this function recalculates the price of the product based on the
     * given current and new revenue margin. This function is supposed to be used for real-time updates, if the
     * page is refreshed the product would already contain the updated price.
     * @param {SteamInventoryItem | AdvancedProductSearchProductResponse} item
     * @param {number} oldFee
     * @param {number} newFee
     */
    static recalculateItemPrice(item, oldFee, newFee) {
        if (isSteamInventoryItem(item)) {
            const basePrice = item.price / (1 - oldFee);
            item.price = basePrice - (basePrice * newFee);
            return;
        }

        const basePrice = item.price / (1 + oldFee);
        item.price = basePrice + (basePrice * newFee);
    }

    /**
     * @param {AdvancedProductSearchProductResponse} product
     * @returns {{ min: number, max: number, initialQuantity: number }}
     */
    static getMinAndMax(product) {
        /**
         * @type {number}
         */
        let min;
        /**
         * @type {number}
         */
        let max;
        /**
         * @type {number}
         */
        let initialQuantity;

        if (product.maximum && product.maximum < product.quantity) {
            max = product.maximum;
        } else {
            max = product.quantity;
        }

        if (product.minimum) {
            min = product.minimum;
        } else {
            min = 1;
        }

        if (product.selectedQuantity) {
            initialQuantity = product.selectedQuantity;
        } else if (product.defaultAmount !== null && product.defaultAmount !== undefined && product.defaultAmount <= max) {
            initialQuantity = product.defaultAmount;
        } else {
            initialQuantity = min;
        }

        return { min, max, initialQuantity };
    }

    /**
     * @param {AdvancedProductSearchProductResponse | SteamInventoryItem} product
     * @param {boolean} isSell
     */
    static getPrice(product, isSell = false) {
        if (isSteamInventoryItem(product)) {
            return product.price;
        }

        if (isSell) {
            return product.expectedPrice * product.selectedQuantity;
        }

        let price = product.price;

        if (product.salePrice > 0) {
            price = product.salePrice;
        }

        return price * product.selectedQuantity;
    }
}
