import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

/**
 * @typedef {Object} ByWebsiteAndCategoryPaginatedResponse
 * @property {GameSelectorOption[]} games
 * @property {number} totalCount
 */

@inject(ApiService, Helper)
export class GameService {
    path = 'Game';
    games;

    /**
     * @param {ApiService} api
     * @param {Helper} helper
     */
    constructor(api, helper) {
        this.api = api;
        this.helper = helper;
    }

    async getAll() {
        if (!this.games) {
            this.games = await this.api.doGet(this.path);
        }
        return this.games;
    }

    async getAllForNavItems() {
        if (!this.games) {
            this.games = await this.api.doGet(`${this.path}/ForNavItems`);
        }
        return this.games;
    }


    /**
     * @param {string} slug
     * @param {'CG'} websiteShortCode
     * @returns {Promise<GameForNav | null>}
     */
    async getGameForNavBySlug(slug, websiteShortCode = 'CG') {
        return await this.api.doGet(`${this.path}/ByGameSlug/${slug}`, { websiteShortCode });
    }

    /**
     * @param {number} gameId
     * @param {string} categoryName
     * @param {'CG'} websiteShortCode
     * @returns {Promise<GameForNav | null>}
     */
    async getGameForNavByIdAndCategory(gameId, categoryName, websiteShortCode = 'CG') {
        return await this.api.doGet(`${this.path}/ByIdAndCategoryName/${gameId}`, { categoryName, websiteShortCode });
    }

    async getFiltered(filter) {
        return await this.api.doGet(`${this.path}/filterBy/${filter}`);
    }

    async getById(id) {
        if (!this.games) {
            this.games = await this.getAll();
        }
        return this.games.filter(x => x.id === id);
    }

    async getByShortName(shortName) {
        if (!this.games) {
            this.games = await this.getAll();
        }
        return this.games.filter(x => x.shortName === shortName);
    }

    /**
     * @param {number} page
     * @param {number} pageSize
     * @param {number?} categoryId
     * @param {string?} query
     * @returns {Promise<ByWebsiteAndCategoryPaginatedResponse | null>}
     */
    async getByWebsiteAndCategoryPaginated(page, pageSize, categoryId, query) {
        return await this.api.doGet(`${this.path}/GetByWebsiteAndCategoryPaginated`, { page, pageSize, categoryId, query, websiteShortCode: 'CG' });
    }
}
