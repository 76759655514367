import { produce } from 'immer';
import { isSteamInventoryItem } from 'resources/helpers/types/sell-types';

/**
 * @type {SwapState}
 */
const initialState = {
    selectedProducts: {
        sellProducts: [],
        purchaseProducts: []
    }
};

/**
 * @param {AppState} state
 * @param {'Sell' | 'Purchase' | 'All'} type
 *
 * @returns {AppState}
 */
export function clearSelectedProducts(state, type) {
    return produce(state, draftState => {
        switch (type) {
            case 'Sell':
                draftState.swapState.selectedProducts.sellProducts = [];
                break;
            case 'Purchase':
                draftState.swapState.selectedProducts.purchaseProducts = [];
                break;
            default:
                draftState.swapState.selectedProducts.sellProducts = [];
                draftState.swapState.selectedProducts.purchaseProducts = [];
                break;
        }
    });
}

/**
 * @param {AppState} state
 * @param {'Sell' | 'Buy'} type
 * @param {AdvancedProductSearchProductResponse | SteamInventoryItem} product
 * @param {boolean} replace
 *
 * @returns {AppState}
 */
export function updateSelectedProducts(state, type, product, replace = false) {
    return produce(state, draftState => {
        let productList;

        if (type === 'Buy') {
            productList = draftState.swapState.selectedProducts.purchaseProducts;
        } else {
            productList = draftState.swapState.selectedProducts.sellProducts;
        }

        const index = productList
            .findIndex(x => isSteamInventoryItem(x) ?
                x.productId === product.productId && x.assetId === product.assetId : x.id === product.id
            );

        if (index === -1) {
            productList.push(product);
        } else if (replace) {
            productList.splice(index, 1, product);
        } else {
            productList.splice(index, 1);
        }
    });
}

export default initialState;
